




















import {Vue, Component, Prop} from "vue-property-decorator";
import JoinListItemType from "./indexType";

@Component({name: "JoinListItem"})
export default class JoinListItem extends Vue implements JoinListItemType{

    get getPhoto(){
        return this.getData && this.getData.hendImg || require("@/assets/icon/Basics/default.png")
    }

    @Prop(Object)
    data!:any
    get getData(){ return this.data }

    @Prop(Number)
    index!:number
    get getIndex(){ return this.index }
}
