




















import {Vue, Component} from "vue-property-decorator";
import JoinListType from "./JoinList";
import ZoomPage from "../../../impView/PageSafety";
import HeadTop from "@/components/ShareComponent/HeadTop.vue"
import PullDownUpList from "@/components/ShareComponent/PullDownUp.vue"
import ListBottom from "@/components/ShareComponent/ListBottom.vue";
import JoinListItem from "@/views/Group/JoinList/components/Item/index.vue";
import {getJoinList} from "@/views/Group/JoinList/Server";
import {joinListType} from "@/Api/Group/indexType";

@Component({name: "JoinList",components:{ HeadTop,PullDownUpList,ListBottom,JoinListItem }})
export default class JoinList extends ZoomPage implements JoinListType{
    PullDown = false
    UpDownBool = true
    StopUp = false
    List:any[] = []
    upData:joinListType={
        pageNo: 1, pageSize: 15, taskId: ''
    }

    mounted(){
        this.loadingShow = false
    }

    activated(){
        let { taskId } = this.$route.query
        this.upData.taskId = taskId as string
        this.handleUpData(true)
    }

    handlePullDown(){
        this.upData.pageNo = 1
        this.PullDown = true
        this.handleUpData(true)
    }

    handleUpDown(){
        if ( this.StopUp )return;
        this.upData.pageNo += 1
        this.UpDownBool = true
        this.handleUpData()
    }

    handleUpData(bool=false){
        getJoinList(this.upData).then(res=>{
            this.PullDown = false
            this.UpDownBool = false
            if ( res.length < this.upData.pageSize )this.StopUp = true;
            if ( bool )
                this.List = res;
            else
                this.List = this.List.concat(res);
        })
    }
}
